import React from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Image from '@components/utility/Image';

const Cta = ({ctaUrl, iconUrl, ctaText, addedClass, iconAddedClass}) => {

    return(
		<Link to={ctaUrl} className={addedClass}>
			<div className="cta-icon">
				<Image imageName={iconUrl} alt="" addedClass={iconAddedClass}></Image>
			</div>
			<div className="cta-inner">
				<span>{ctaText}</span>
				<Image imageName='cta-blue-chevron.png' alt=""></Image>
			</div>
		</Link>
    )
}

export default Cta;

Cta.propTypes = {
    iconUrl: PropTypes.string.isRequired,
    ctaText: PropTypes.node.isRequired,
    ctaUrl: PropTypes.string.isRequired,
    addedClass: PropTypes.string,
    iconAddedClass: PropTypes.string,
}
