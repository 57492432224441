import React, { useEffect } from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
import { KfAccordion, KfAccordionItem, KfButton } from '@klickinc/kf-react-components';
import GTM from '@src/utils/GTM.js';
import Seo from '@components/utility/SEO';
import Cta from '@components/content/Cta';
import Hero from '@components/content/hero/Hero';
import Image from '@components/utility/Image';
import Loadable from '@loadable/component';

import './styles.scss';

const Chart1 = Loadable(() => import('@components/content/chart/Chart1'));
const Chart2 = Loadable(() => import('@components/content/chart/Chart2'));
const WhizzbangLine = Loadable(() => import('@components/content/WhizzbangLine'));

const MuscleFunction = () => {
	const closeAccordion = (id) => {
		const el = document.querySelector(`#${id}`);

		if (el) {
			const trigger = el.querySelector('.kf-accordion__trigger button');
			if (trigger) trigger.click();
		}
	};

	const handleToggle = (e) => {
		const trigger = e.target;
		const expanded = trigger.getAttribute('aria-expanded') === 'true';
		const parent = trigger.closest('.kf-accordion');
		if (parent) {
			const id = parent.id.replace('acc-', '').replace('-1', '').replace('-2', '').replace('-', ' ');
			const label = `See ${id} summary`;
			GTM.accordionToggle(expanded, label);
		}

		if (expanded) {
			trigger.scrollIntoView();
		}
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const triggers = document.querySelectorAll('.kf-accordion__trigger button');
			triggers.forEach(trigger => {
				trigger.addEventListener('click', (e) => handleToggle(e, trigger));
			});
		}
		return () => {
			const triggers = document.querySelectorAll('.kf-accordion__trigger button');
			triggers.forEach(trigger => {
				trigger.removeEventListener('click', (e) => handleToggle(e, trigger));
			});
		}
    },[])

	return (
		<Layout>
			<Seo title='Efficacy: Muscle Function | EMFLAZA® (deflazacort)' description='See data on the effectiveness of EMFLAZA® (deflazacort) in improving muscle function. View full Prescribing Information & Important Safety Information.' />
			<div className="page--muscle-function">
				<Hero
					heroImage='hero-muscle-function.png'
					heroImageMobile='hero-muscle-function-mobile.png'
					breadcrumbs={['Efficacy', 'Muscle Function']}
					title={<>Acting on efficacy from the start<sup>3,7</sup></>}
					addedClass='muscle-function-breadcrumbs'
				>
					After the first 3 months and beyond, help patients find stability by improving muscle strength.<sup className='text-xxs'>3,7</sup>
				</Hero>

				<section className='mt-3 3xl:-mt-40'>
					<div className='container'>
						<h2 className='mb-3 text-lg font-bold leading-5 text-center uppercase md:text-2xl text-emflaza-blue-400 xl:ml-20'>Muscle strength after the first 3 months<sup>3,7</sup></h2>
						<Chart1 />
						<div className='mt-8 muscle-strength-study md:mt-4'>
							<Image imageName='study-year-2016.png' alt="2016 Study"/>
							<div className='mt-6 md:mt-0'>
								<p>The safety and efficacy of deflazacort were evaluated vs prednisone in a phase &#8546;, multicenter, randomized, double&#8209;blind, placebo&#8209;controlled 52&#8209;week study of 196 boys aged 5 to 15 years old with DMD onset of weakness <br className='hidden xl:inline'/> &lt;5 years of age.<sup>7</sup></p>
								<KfAccordion addedId='muscle-strength-study-1' addedClass="mt-2 md:mt-4">
									<KfAccordionItem expandLabel="See Study Summary +" collapseLabel="Close Study Summary -" addedId="kf-accordion__muscle-strength-study-1">
									<h4 className='mb-5 text-base font-bold'>Griggs 2016 Study Summary</h4>
										<h5 className='font-bold'>Efficacy and safety of deflazacort vs prednisone and placebo for <br classname='hidden md:inline'/>Duchenne muscular dystrophy</h5>
										<p>A phase &#8546;, double&#8209;blind, randomized, placebo&#8209;controlled, multicenter study of 196 patients with DMD aged 5 to 15 years old.</p>
										<p>The primary efficacy endpoint was average change in muscle strength from baseline to Week 12 compared with placebo. At Week 12, all active treatment arms (deflazacort 0.9 and 1.2 mg/kg/day and prednisone 0.75 mg/kg/day) preserved muscle strength in patients with DMD.</p>
										<p>In a secondary endpoint analysis, deflazacort 0.9 mg/kg/day demonstrated a significant improvement in muscle strength vs prednisone from Week 12 to 52.</p>
										<p>Deflazacort preserved motor function in patients with DMD. From baseline to Week 52, an exploratory endpoint showed that patients receiving deflazacort had significant improvements in the time to climb 4 stairs compared with prednisone. From Week 12 to Week 52, both deflazacort groups demonstrated greater numerical improvements in time from supine to stand, time to climb 4 stairs, and time to run or walk 30 feet compared with the prednisone&#8209;treated participants, but these did not reach significance since the trial was not statistically designed to detect this difference.</p>
										<p>The 3 most commonly reported treatment&#8209;related adverse events (AEs) were Cushingoid appearance, erythema, and hirsutism. Weight gain&#8209;related AEs were more likely to be moderate or severe with prednisone compared with deflazacort. There were more AEs, serious AEs, and AEs leading to discontinuation in prednisone&#8209;treated patients than in either deflazacort&#8209;treated cohort. Overall, psychiatric AEs were more common in the prednisone group than in the deflazacort groups; however, psychotic disorder was reported more frequently in the deflazacort 0.9 mg/kg/day group. Cataracts occurred at a higher rate with daily deflazacort than prednisone.</p>
										<h5 className='mt-5 font-bold'>Study Limitations</h5>
										<p>Comparisons between deflazacort and prednisone are not included in the approved Prescribing Information for deflazacort because prednisone is not an approved treatment for DMD. This study examined the recommended deflazacort dose of 0.9 mg/kg/day as well as a dose of 1.2 mg/kg/day. The study had a 52&#8209;week duration of treatment, which did not allow for long-term follow&#8209;up to observe the effect of steroids over longer periods of time, and was not powered to achieve significance. The deflazacort and prednisone doses administered in this study were from various manufacturers in and outside the United States.</p>
										<h5 className='mt-5 font-bold'>Financial Disclosures of Study Sponsors</h5>
										<p>This study was sponsored with funding provided by Nordic Merrill Dow and the Muscular Dystrophy Association (MDA).</p>
										<p className='mt-5 mb-6'>Griggs RC, Miller JP, Greenberg CR, et al. Efficacy and safety of deflazacort vs prednisone and placebo for Duchenne muscular dystrophy. <em>Neurology</em>. 2016;87(20):2123-2131.</p>
										<KfButton clickFn={() => closeAccordion('muscle-strength-study-1')} addedClass='text-emflaza-orange-300 underline'>Close Study Summary -</KfButton>
									</KfAccordionItem>
								</KfAccordion>
							</div>
						</div>
					</div>
				</section>

				<section className='section-2 relative my-8 md:mb-16 min-h-[200px] md:min-h-[23.438rem]'>
					<div className='max-w-[280px] md:max-w-[553px] 2xl:max-w-[37.5rem] 3xl:max-w-3xl absolute z-negative'>
						<Image imageName='muscle-blue-cutout.png' alt="" />
					</div>
					<div className='container pt-16 pb-8 pl-6 pr-0 lg:pt-36 desktop:pl-0'>
						<p className='text-xl font-black leading-5 text-white uppercase font-gotham md:text-4xl'>Improved <br />muscle strength <br />over time<sup className='superscript'>7</sup></p>
					</div>
				</section>

				<section className='relative'>
					<div className='container'>
						<h2 className='mb-3 text-lg font-bold leading-5 text-center uppercase md:text-2xl text-emflaza-blue-400 xl:ml-20'>Change in average muscle strength score <br className='hidden md:inline'/> through <br className='inline md:hidden'/> 1 year<sup>7</sup></h2>
						<Chart2 />
						<WhizzbangLine imageName='whizzbang-line-5.png' />
						<div className='mt-5 md:mt-15 muscle-strength-study'>
							<Image imageName='study-year-2016.png' alt="2016 Study"/>
							<div className='mt-6 md:mt-0'>
								<p>The safety and efficacy of deflazacort were evaluated vs prednisone in a phase &#8546;, multicenter, randomized, double&#8209;blind, placebo&#8209;controlled 52&#8209;week study of 196 boys aged 5 to 15 years old with DMD onset of weakness <br className='hidden xl:inline'/> &lt;5 years of age.<sup>7</sup></p>
								<KfAccordion addedId='muscle-strength-study-2' addedClass="mt-2 md:mt-4">
									<KfAccordionItem expandLabel="See Study Summary +" collapseLabel="Close Study Summary -" addedId="kf-accordion__muscle-strength-study-2">
										<h4 className='mb-5 text-base font-bold'>Griggs 2016 Study Summary</h4>
										<h5 className='font-bold'>Efficacy and safety of deflazacort vs prednisone and placebo for <br classname='hidden md:inline'/>Duchenne muscular dystrophy</h5>
										<p>A phase &#8546;, double&#8209;blind, randomized, placebo&#8209;controlled, multicenter study of 196 patients with DMD aged 5 to 15 years old.</p>
										<p>The primary efficacy endpoint was average change in muscle strength from baseline to Week 12 compared with placebo. At Week 12, all active treatment arms (deflazacort 0.9 and 1.2 mg/kg/day and prednisone 0.75 mg/kg/day) preserved muscle strength in patients with DMD.</p>
										<p>In a secondary endpoint analysis, deflazacort 0.9 mg/kg/day demonstrated a significant improvement in muscle strength vs prednisone from Week 12 to 52.</p>
										<p>Deflazacort preserved motor function in patients with DMD. From baseline to Week 52, an exploratory endpoint showed that patients receiving deflazacort had significant improvements in the time to climb 4 stairs compared with prednisone. From Week 12 to Week 52, both deflazacort groups demonstrated greater numerical improvements in time from supine to stand, time to climb 4 stairs, and time to run or walk 30 feet compared with the prednisone&#8209;treated participants, but these did not reach significance since the trial was not statistically designed to detect this difference.</p>
										<p>The 3 most commonly reported treatment&#8209;related adverse events (AEs) were Cushingoid appearance, erythema, and hirsutism. Weight gain&#8209;related AEs were more likely to be moderate or severe with prednisone compared with deflazacort. There were more AEs, serious AEs, and AEs leading to discontinuation in prednisone&#8209;treated patients than in either deflazacort&#8209;treated cohort. Overall, psychiatric AEs were more common in the prednisone group than in the deflazacort groups; however, psychotic disorder was reported more frequently in the deflazacort 0.9 mg/kg/day group. Cataracts occurred at a higher rate with daily deflazacort than prednisone.</p>
										<h5 className='mt-5 font-bold'>Study Limitations</h5>
										<p>Comparisons between deflazacort and prednisone are not included in the approved Prescribing Information for deflazacort because prednisone is not an approved treatment for DMD. This study examined the recommended deflazacort dose of 0.9 mg/kg/day as well as a dose of 1.2 mg/kg/day. The study had a 52&#8209;week duration of treatment, which did not allow for long-term follow&#8209;up to observe the effect of steroids over longer periods of time, and was not powered to achieve significance. The deflazacort and prednisone doses administered in this study were from various manufacturers in and outside the United States.</p>
										<h5 className='mt-5 font-bold'>Financial Disclosures of Study Sponsors</h5>
										<p>This study was sponsored with funding provided by Nordic Merrill Dow and the Muscular Dystrophy Association (MDA).</p>
										<p className='mt-5 mb-6'>Griggs RC, Miller JP, Greenberg CR, et al. Efficacy and safety of deflazacort vs prednisone and placebo for Duchenne muscular dystrophy. <em>Neurology</em>. 2016;87(20):2123-2131.</p>
										<KfButton clickFn={() => closeAccordion('muscle-strength-study-2')} addedClass='text-emflaza-orange-300 underline'>Close Study Summary -</KfButton>
									</KfAccordionItem>
								</KfAccordion>
							</div>
						</div>
					</div>
				</section>

				<section>
					<div className='container'>
						<h2 className='font-bold mt-15 font-roboto md:mt-24'>Study Limitations</h2>
						<p className='md:max-w-[747px]'>Comparisons between deflazacort and prednisone are not included in the Prescribing Information for deflazacort because prednisone is not an approved treatment for DMD.</p>
						<div className='flex flex-wrap my-8 space-y-5 md:space-y-0 md:space-x-5 md:mt-10'>
							<Cta ctaUrl='/getting-started/switch-considerations' iconUrl={'cta-s-icon.png'} ctaText='Switching Corticosteroids' addedClass='cta flex-1'/>
							<Cta ctaUrl='/efficacy/real-world-data' iconUrl={'cta-world-icon-2.png'} ctaText='Real-World Data' addedClass='cta flex-1' iconAddedClass='max-w-[63px]'/>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default MuscleFunction;
