import React from 'react';
import PropTypes from 'prop-types';
import { KfHeroUtility } from '@klickinc/kf-react-components';
import Image from '@components/utility/Image';
import Loadable from '@loadable/component';
import './styles.scss';

const Breadcrumb = Loadable(() => import('@components/content/Breadcrumb'));

const Hero = ({
	heroImage,
	heroImageAlt,
	heroImageMobile,
	heroImageTablet,
	breadcrumbs,
	useBreadcrumbs,
	objectFit,
	title,
	children,
	addedClass,
}) => {
	return (
		<div className='relative blue-bg hero'>
			<Image
				imageName={heroImage}
				imageNameMobile={heroImageMobile}
				imageNameTablet={heroImageTablet}
				objectFit={objectFit}
				alt={heroImageAlt}
				loading='eager'
			/>
			<div className='px-8 py-6 md:px-0 md:py-0 md:pb-6 hero__container'>
				{useBreadcrumbs ?
					<Breadcrumb breadcrumbs={breadcrumbs} addedClass={addedClass} />
					: null
				}
				<KfHeroUtility addedClass='heading heading-hero'>{title}</KfHeroUtility>

				{children && (
					<p className='max-w-2xl my-5 text-base md:leading-snug md:text-lg'>
						{children}
					</p>
				)}
			</div>
		</div>
	);
};

Hero.defaultProps = {
	heroImageAlt: '',
	heroImageMobile: null,
	heroImageTablet: null,
	breadcrumbs: null,
	useBreadcrumbs: true,
	children: null,
	addedClass: '',
	objectFit: 'cover',
};

Hero.propTypes = {
	heroImage: PropTypes.string.isRequired,
	heroImageAlt: PropTypes.string,
	heroImageTablet: PropTypes.string,
	heroImageMobile: PropTypes.string,
	title: PropTypes.node.isRequired,
	breadcrumbs: PropTypes.array,
	useBreadcrumbs: PropTypes.bool,
	children: PropTypes.node,
	addedClass: PropTypes.string,
	objectFit: PropTypes.oneOf(['cover','contain']),
};

export default Hero;
